<template>
	<div class="modal-dialog modal-dialog-open">
		<div class="modal">
			<div class="modal-header">
				<h3>Change Payment Method</h3>
				<span class="close-modal" @click="handleClickCloseModal" v-show="!submitting"></span>
			</div>
			<div class="modal-body">
				<div class="loading" v-show="!initialized">
					<div>Initializing</div>
					<div class="loader"></div>
				</div>
				<div class="dev-payment-method-container" :style="getStyle()">
					<div id="devPaymentProviderWidgetContainer">
						<template v-if="tenantConfigurations?.PaymentProvider === $_paymentProviders.BRAINTREE">
							<BraintreePaymentMethod @initialized="handleWidgetInitialized"> </BraintreePaymentMethod>
						</template>
						<template v-if="tenantConfigurations?.PaymentProvider === $_paymentProviders.STRIPE">
							<StripePaymentMethod @initialized="handleWidgetInitialized"></StripePaymentMethod>
						</template>
					</div>
					<button
						ref="btnConfirmChangePaymentMethod"
						type="button"
						class="ladda-button"
						data-style="expand-right"
						data-color="mint"
						@click.prevent="handleClickConfirmChangePaymentMethod"
					>
						<span class="ladda-label">{{ btnLabel }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BraintreePaymentMethod from '@/components/paymentmethods/BraintreePaymentMethod.vue';
import StripePaymentMethod from '@/components/paymentmethods/StripePaymentMethod.vue';
import * as Ladda from 'ladda';
import CommonService from '@/services/CommonService';

export default {
	name: 'ModalChangePaymentMethod',

	components: {
		BraintreePaymentMethod,
		StripePaymentMethod,
	},

	data() {
		return {
			initialized: false,
			submitting: false,
			submitted: false,
			btnLabel: 'Confirm',
		};
	},

	computed: {
		tenantConfigurations() {
			const configurations = this.$store.getters['auth/configurations'];
			return configurations;
		},
	},

	methods: {
		getStyle() {
			if (this.initialized) {
				return null;
			} else {
				return { opacity: 0 };
			}
		},

		handleClickCloseModal() {
			this.$emit('close');
		},

		handleClickConfirmChangePaymentMethod() {
			if (!this.$_paymentProviderWidget) {
				return false;
			}

			if (this.submitted) {
				this.handleClickCloseModal();

				return false;
			}

			this.submitting = true;

			const ldbtnConfirmChangePaymentMethod = Ladda.create(this.$refs.btnConfirmChangePaymentMethod);
			ldbtnConfirmChangePaymentMethod.start();

			this.$_paymentProviderWidget.processChangePaymentMethod({
				success: () => {
					this.$emit('payment-method-changed');

					this.submitting = false;
					this.submitted = true;

					// ldbtnConfirmChangePaymentMethod.stop();

					// this.btnLabel = 'OK';

					this.$nextTick(() => {
						this.handleClickCloseModal();
					});
				},
				failed: (err) => {
					console.warn('❌ this.$_paymentProviderWidget.processChangePaymentMethod failed()', err);

					if (err && typeof err === 'string') {
						this.$toast.error(err, { timeout: 50000 });
					} else {
						this.$toast.error('There is something wrong when changing your payment method. Please contact support.', { timeout: 50000 });
					}

					this.submitting = false;
					this.submitted = false;

					ldbtnConfirmChangePaymentMethod.stop();

					this.btnLabel = 'Submit';
				},
			});
		},

		handleWidgetInitialized(args) {
			this.initialized = true;

			this.$_paymentProviderWidget = args.widget;
		},
	},

	created() {
		this.$_paymentProviderWidget = null;
		this.$_paymentProviders = CommonService.PAYMENT_PROVIDERS;
	},

	mounted() {},
};
</script>

<style scoped>
.loading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading div:first-child {
	margin-right: 12px;
}

/* HTML: <div class="loader"></div> */
.loader {
	width: 120px;
	height: 22px;
	border-radius: 20px;
	color: #514b82;
	border: 2px solid;
	position: relative;
}
.loader::before {
	content: '';
	position: absolute;
	margin: 2px;
	inset: 0 100% 0 0;
	border-radius: inherit;
	background: currentColor;
	animation: l6 2s infinite;
}
@keyframes l6 {
	100% {
		inset: 0;
	}
}

#devPaymentProviderWidgetContainer {
	margin-bottom: 25px;
}
</style>
