import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
// import { createLogger } from 'vuex';
import contact from './modules/contact';
import DataService from '@/services/DataService';

// Load Vuex
Vue.use(Vuex);

// const logger = createLogger({
// 	collapsed: true, // auto-expand logged mutations
// 	// filter(mutation, stateBefore, stateAfter) {
// 	// 	// returns `true` if a mutation should be logged
// 	// 	// `mutation` is a `{ type, payload }`
// 	// 	return mutation.type !== 'aBlocklistedMutation';
// 	// },
// 	// actionFilter(action, state) {
// 	// 	// same as `filter` but for actions
// 	// 	// `action` is a `{ type, payload }`
// 	// 	return action.type !== 'aBlocklistedAction';
// 	// },
// 	// transformer(state) {
// 	// 	// transform the state before logging it.
// 	// 	// for example return only a specific sub-tree
// 	// 	return state.subTree;
// 	// },
// 	// mutationTransformer(mutation) {
// 	// 	// mutations are logged in the format of `{ type, payload }`
// 	// 	// we can format it any way we want.
// 	// 	return mutation.type;
// 	// },
// 	// actionTransformer(action) {
// 	// 	// Same as mutationTransformer but for actions
// 	// 	return action.type;
// 	// },
// 	logActions: true, // Log Actions
// 	logMutations: true, // Log mutations
// 	logger: console, // implementation of the `console` API, default `console`
// });

// Create store

const authState = createPersistedState({
	paths: ['auth'],
});

// main store
export default new Vuex.Store({
	modules: {
		auth,
		contact,
	},
	plugins: [
		// createPersistedState({
		// 	storage: window.sessionStorage,
		// }),
		// logger,
		authState,
	],

	state() {
		return {
			resultsFetching: false,
			resultsFetched: false,
			results: [],
		};
	},

	getters: {
		resultsFetching(state) {
			return state.resultsFetching;
		},

		results(state) {
			return state.results;
		},
	},

	mutations: {
		setResultsFetching(state, payload) {
			state.resultsFetching = payload;
		},
		setResultsFetched(state, payload) {
			state.resultsFetched = payload;
		},

		setResults(state, payload) {
			state.results = payload.results;
		},
	},

	actions: {
		resetState({ commit }) {
			commit('setResults', { results: [] });
			commit('setResultsFetched', false);
		},

		logout({ dispatch }) {
			dispatch('auth/resetState', null, { root: true });
			dispatch('contact/resetState', null, { root: true });
			dispatch('resetState');
		},

		fetchLotteryResults({ state, commit }) {
			return new Promise((resolve) => {
				if (state.resultsFetched || state.resultsFetching) {
					resolve();
				} else {
					(async () => {
						commit('setResultsFetching', true);

						await DataService.getLotteryResults({
							success: (d) => {
								commit('setResults', { results: d.results });
								commit('setResultsFetched', true);
								commit('setResultsFetching', false);

								resolve();
							},
							failed: () => {
								commit('setResults', { results: [] });
								commit('setResultsFetched', true);
								commit('setResultsFetching', false);

								resolve();
							},
						});
					})();
				}
			});
		},
	},
});
