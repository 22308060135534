import { getTime } from 'date-fns';
import httpClient from '@/services/HttpClient';
import ModelService from '@/services/ModelService';

import store from '@/store/index';
import CommonService from '@/services/CommonService';

function getRequestConfigForPrimisProxy() {
	const authToken = store.getters['auth/token'];
	return {
		headers: {
			// 'x-apikey': process.env.VUE_APP_PRIMIS_API_KEY,
			Authorization: `Bearer ${authToken}`,
		},
	};
}

const dataService = {
	ping() {
		return `Data Service at ${new Date().toISOString()}`;
	},

	async login({ email, password, success, failed }) {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;
			const response = await httpClient.post(`${apiUrl}/auth/login`, {
				LoginID: email,
				Pswd: password,
			});

			if (response && response.data) {
				success({
					contactId: response.data.contactId,
					token: response.data.token,
					configurations: response.data.configurations,
				});
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async authCheck() {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const response = await httpClient.get(`${apiUrl}/auth/check`, getRequestConfigForPrimisProxy());

			if (response && response.data) {
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	},

	async logout() {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;
			const response = await httpClient.post(`${apiUrl}/portal/logout`, null, getRequestConfigForPrimisProxy());
			if (response && response.data) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	async getContactDetails({ contactId, success, failed }) {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const response = await httpClient.get(`${apiUrl}/portal/contact?contactId=${contactId}`, getRequestConfigForPrimisProxy());

			if (response && response.data && response.data.contact && Array.isArray(response.data.contact) && response.data.contact.length > 0) {
				const raw = response.data.contact[0];

				// DEBUG
				window.localStorage.setItem('__raw_contactDetails__', JSON.stringify(raw));

				// need to map
				const model = ModelService.createContactDetailsModel(raw);

				success({
					contactDetails: model,
					rawContactDetails: raw,
				});
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	getTicketsWithAppliedOptions(tickets, options) {
		let items = [];

		// apply filter
		switch (options.filter) {
			case 'all':
				items = tickets.filter((_item) => !!_item);
				break;

			case 'closed':
				// items = state.contactDetails.Tickets.filter((_item) => {
				// 	const tsDate = getTime(_item.LotteryCloseDate);
				// 	const tsNow = getTime(new Date());
				// 	return tsNow > tsDate;
				// });
				items = tickets.filter((_item) => _item.LotteryStatus === 'Closed');
				break;

			case 'current':
				// items = state.contactDetails.Tickets.filter((_item) => {
				// 	const tsDate = getTime(_item.LotteryDrawDate);
				// 	const tsNow = getTime(new Date());
				// 	return tsNow < tsDate;
				// });
				items = tickets.filter((_item) => _item.LotteryStatus === 'Current');
				break;

			default:
				break;
		}

		// apply sort
		switch (options.sortBy) {
			case 'LotteryName':
				items = items.sort((a, b) => {
					return a.LotteryName.localeCompare(b.LotteryName);
				});
				break;

			case 'LotteryDrawingName':
				items = items.sort((a, b) => {
					return a.LotteryDrawingName.localeCompare(b.LotteryDrawingName);
				});
				break;

			case 'LotteryCloseDate':
				items = items.sort((a, b) => {
					const tsCloseDateA = getTime(a.LotteryCloseDate);
					const tsCloseDateB = getTime(b.LotteryCloseDate);
					return tsCloseDateA - tsCloseDateB; // ASC
					// return tsCloseDateB - tsCloseDateA; // DESC
				});
				break;

			case 'LotteryDrawDate':
				items = items.sort((a, b) => {
					const tsCloseDateA = getTime(a.LotteryDrawDate);
					const tsCloseDateB = getTime(b.LotteryDrawDate);
					return tsCloseDateA - tsCloseDateB; // ASC
					// return tsCloseDateB - tsCloseDateA; // DESC
				});
				break;

			default:
				break;
		}

		return items;
	},

	async getActiveLotteries({ success, failed }) {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const response = await httpClient.get(`${apiUrl}/portal/active-lotteries`, getRequestConfigForPrimisProxy());

			if (response && response.data && response.data.lottery) {
				const raw = response.data.lottery;

				// filter
				const filtered = raw.filter((r) => !!r.lotteryLink && r.lotteryLink.length > 0);

				// need to map
				const model = ModelService.createActiveLotteriesModel(filtered);

				success({
					activeLotteries: model,
				});
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async getLotteryResults({ success, failed }) {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const response = await httpClient.get(`${apiUrl}/portal/lottery-results`, getRequestConfigForPrimisProxy());

			if (response && response.data && response.data.lottery) {
				const raw = response.data.lottery;

				// need to map
				const model = ModelService.createLotteryResultsModel(raw);

				success({
					results: model,
				});
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async getAvailableSubscriptions({ success, failed }) {
		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const response = await httpClient.get(`${apiUrl}/portal/available-subscriptions`, getRequestConfigForPrimisProxy());

			if (response && response.data && response.data.lotteryType) {
				const raw = response.data.lotteryType;

				// need to map
				const model = ModelService.createAvailableSubscriptionsModel(raw);

				success({
					subscriptions: model,
				});
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async subscribe({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['subscription', 'contactId', 'paymentMethodToken', 'noOfTickets'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				ContactId: options.contactId,
				LotteryType: options.subscription.LotteryType,
				NoOfTickets: options.noOfTickets,
				PaymentMethodToken: options.paymentMethodToken,
			};

			const response = await httpClient.post(`${apiUrl}/portal/subscribe`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async pauseSubscription({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['subscription', 'contactId', 'pauseUntil'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				ContactId: options.contactId,
				LotteryType: options.subscription.LotteryType,
				PauseUntil: options.pauseUntil,
			};

			const response = await httpClient.post(`${apiUrl}/portal/pause-subscription`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async cancelSubscription({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['subscription', 'contactId'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				ContactId: options.contactId,
				LotteryType: options.subscription.LotteryType,
			};

			const response = await httpClient.post(`${apiUrl}/portal/cancel-subscription`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async updateSubscription({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['subscription', 'contactId', 'paymentMethodToken', 'noOfTickets'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				ContactId: options.contactId,
				LotteryType: options.subscription.LotteryType,
				NoOfTickets: options.noOfTickets,
				PaymentMethodToken: options.paymentMethodToken,
			};

			const response = await httpClient.post(`${apiUrl}/portal/update-subscription`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async resumeSubscription({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['subscription', 'contactId', 'paymentMethodToken'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				ContactId: options.contactId,
				LotteryType: options.subscription.LotteryType,
				NoOfTickets: null, // options.subscription.TicketsCount,
				PaymentMethodToken: null, // options.paymentMethodToken,
			};

			const response = await httpClient.post(`${apiUrl}/portal/resume-subscription`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async updatePaymentMethod({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		// const mustHaveOptionsProperties = ['contactId', 'nonce'];
		const mustHaveOptionsProperties = ['contactId'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				contactId: options.contactId,
			};

			const tenantConfigurations = store.getters['auth/configurations'];
			if (tenantConfigurations.PaymentProvider === CommonService.PAYMENT_PROVIDERS.BRAINTREE) {
				d.Data = {
					ClientNonce: options.nonce,
				};
			} else if (tenantConfigurations.PaymentProvider === CommonService.PAYMENT_PROVIDERS.STRIPE) {
				d.Data = {
					CardToken: options.cardToken,
					CardHolderName: options.cardHolderName,
					CardHolderEmail: options.cardHolderEmail,
				};
			}

			const response = await httpClient.post(`${apiUrl}/portal/update-payment-method`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			console.warn(err);
			failed(err);
		}
	},

	async updateContact({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['contact'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				contact: options.contact,
			};

			const response = await httpClient.post(`${apiUrl}/portal/contact`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async changePassword({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['oldPassword', 'newPassword', 'loginId'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				// model: {
				loginId: options.loginId,
				newPswd: options.newPassword,
				oldPswd: options.oldPassword,
				// },
			};

			const response = await httpClient.put(`${apiUrl}/portal/password`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success();
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async resetPassword({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['email'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				email: options.email,
			};

			const response = await httpClient.post(`${apiUrl}/auth/reset-password`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success(response.data);
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},

	async confirmResetPassword({ options, success, failed }) {
		if (!options) {
			failed();
			return false;
		}

		const optionsKeys = Object.keys(options);
		const mustHaveOptionsProperties = ['token', 'email', 'id', 'password'];

		const validOptions = mustHaveOptionsProperties.every((_p) => {
			const hasKey = optionsKeys.includes(_p);
			return hasKey;
		});

		if (!validOptions) {
			failed();
			return false;
		}

		try {
			const apiUrl = process.env.VUE_APP_PRIMIS_API_URL;

			const d = {
				token: options.token,
				email: options.email,
				id: options.id,
				password: options.password,
			};

			const response = await httpClient.post(`${apiUrl}/auth/confirm-reset-password`, d, getRequestConfigForPrimisProxy());

			if (response && [200, 204].includes(response.status)) {
				success(response.data);
			} else {
				failed(response);
			}
		} catch (err) {
			failed(err);
		}
	},
};

export default {
	ping: dataService.ping,

	login: dataService.login,
	authCheck: dataService.authCheck,
	logout: dataService.logout,

	getContactDetails: dataService.getContactDetails,

	getTicketsWithAppliedOptions: dataService.getTicketsWithAppliedOptions,

	getActiveLotteries: dataService.getActiveLotteries,
	getLotteryResults: dataService.getLotteryResults,

	getAvailableSubscriptions: dataService.getAvailableSubscriptions,
	subscribe: dataService.subscribe,
	updateSubscription: dataService.updateSubscription,
	cancelSubscription: dataService.cancelSubscription,
	pauseSubscription: dataService.pauseSubscription,
	resumeSubscription: dataService.resumeSubscription,

	updatePaymentMethod: dataService.updatePaymentMethod,

	updateContact: dataService.updateContact,

	changePassword: dataService.changePassword,
	resetPassword: dataService.resetPassword,
	confirmResetPassword: dataService.confirmResetPassword,
};
