<template>
	<div id="braintreeDropInContainer"></div>
</template>

<script>
import DataService from '@/services/DataService';
const dropin = require('braintree-web-drop-in');

export default {
	name: 'BraintreePaymentMethod',

	methods: {
		processChangePaymentMethod(args) {
			if (this.$_braintreeDropInUIInstance) {
				this.$_braintreeDropInUIInstance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
					if (!requestPaymentMethodErr) {
						// NOTE: need to update payment method in primis
						(async () => {
							await DataService.updatePaymentMethod({
								options: {
									contactId: this.$store.getters['contact/contactId'],
									nonce: payload.nonce,
								},
								success: () => {
									args.success();
								},
								failed: (err) => {
									console.log('DataService.updatePaymentMethod failed()', err);
									args.failed();
								},
							});
						})();
					} else {
						console.warn('❌ [ERROR] this.$_braintreeDropInUIInstance.requestPaymentMethod ', requestPaymentMethodErr);
						args.failed();
					}
				});
			} else {
				console.warn('❌ [ERROR] this.$_braintreeDropInUIInstance NULL ');
				args.failed();
			}
		},
	},

	mounted() {
		const paymentProviderConfiguration = this.$store.getters['auth/configurations'].PaymentProviderConfiguration;
		const dropInAuthorizationKey = atob(paymentProviderConfiguration.DropInAuthorizationKey);
		dropin.create(
			{
				authorization: dropInAuthorizationKey,
				container: '#braintreeDropInContainer',
			},
			(createErr, instance) => {
				this.$_braintreeDropInUIInstance = instance;

				this.$nextTick(() => {
					// this.initialized = true;
					this.$emit('initialized', { widget: this });
				});
			}
		);
	},

	created() {
		this.$_braintreeDropInUIInstance = null;
	},
};
</script>
