<template>
	<div id="pause-subscription" class="modal-dialog modal-dialog-open" v-if="subscription">
		<div class="modal">
			<div class="modal-header">
				<h3>Pause Subscription</h3>
				<span class="close-modal" @click="handleClickCloseModal"></span>
			</div>
			<div class="modal-body">
				<p style="text-align: center">We’ll pause your subscription and retain all your information for when you’re ready to resume.</p>
				<div class="field select" style="text-align: center; max-width: 300px; margin-left: auto; margin-right: auto">
					<label>
						<strong>Pause subscription for:</strong>
					</label>
					<select v-model.number="pausedUntil.months">
						<option :value="1">1 month</option>
						<option :value="2">2 months</option>
						<option :value="3">3 months</option>
						<option :value="4">4 months</option>
						<option :value="5">5 months</option>
						<option :value="6">6 months</option>
						<option :value="7">7 months</option>
						<option :value="8">8 months</option>
						<option :value="9">9 months</option>
						<option :value="10">10 months</option>
						<option :value="11">11 months</option>
						<option :value="12">12 months</option>
					</select>
				</div>
				<p style="text-align: center">
					<a class="btn close-modal" @click.prevent="handleClickPauseSubscription($event, subscription)"> Pause Subscription </a>
				</p>
				<p style="text-align: center">
					<a class="close-modal" @click.prevent="handleClickCloseModal"> Not Now </a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import DataService from '@/services/DataService';
import { addMonths, formatISO } from 'date-fns';

export default {
	name: 'ModalPauseSubscription',
	props: {
		subscription: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			pausedUntil: {
				months: 1,
			},
		};
	},

	computed: {},

	methods: {
		handleClickCloseModal() {
			this.$emit('close');
		},

		async handleClickPauseSubscription($event, subscription) {
			const pausedUntil = formatISO(addMonths(new Date(), this.pausedUntil.months));

			await DataService.pauseSubscription({
				success: () => {
					this.$emit('subscription-paused', { subscription, pausedUntil });
				},
				failed: () => {
					this.$emit('close');
				},
				options: {
					subscription: subscription,
					contactId: this.$store.getters['contact/contactId'],
					pauseUntil: pausedUntil,
				},
			});
		},
	},

	created() {},
};
</script>
