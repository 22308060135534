<template>
	<div id="cancel-subscription" class="modal-dialog modal-dialog-open" v-if="subscription">
		<div class="modal">
			<div class="modal-header">
				<h3>Cancel Subscription</h3>
				<span class="close-modal" @click="handleClickCloseModal"></span>
			</div>
			<div class="modal-body">
				<p style="text-align: center">
					We're sorry to see you go! Remember, by cancelling, you'll miss out on automatic ticket reservations and exclusive member perks.
				</p>
				<p style="text-align: center">If you change your mind, we're always here to welcome you back!</p>
				<p style="text-align: center">
					<a class="btn close-modal" @click.prevent="handleClickCancelSubscription($event, subscription)"> Cancel Subscription </a>
				</p>
				<p style="text-align: center">
					<a class="close-modal" @click.prevent="handleClickCloseModal"> Not Now </a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import DataService from '@/services/DataService';

export default {
	name: 'ModalSubscribe',
	props: {
		subscription: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {};
	},

	computed: {},

	methods: {
		handleClickCloseModal() {
			this.$emit('close');
		},

		async handleClickCancelSubscription($event, subscription) {
			await DataService.cancelSubscription({
				success: () => {
					this.$emit('subscription-cancelled', { subscription });
				},
				failed: () => {
					this.$emit('close');
				},
				options: {
					subscription: subscription,
					contactId: this.$store.getters['contact/contactId'],
				},
			});
		},
	},

	created() {},
};
</script>
