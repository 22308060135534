<template>
	<div id="stripePaymentMethodContainer">
		<div id="payment-element"></div>
	</div>
</template>

<script>
import DataService from '@/services/DataService';

export default {
	name: 'StripePaymentMethod',

	methods: {
		processChangePaymentMethod(args) {
			(async () => {
				try {
					// Create a token with the card data
					const createTokenResult = await this.$_stripe.createToken(this.$_paymentElement);
					console.log('createTokenResult', createTokenResult);
					if (createTokenResult.error) {
						console.warn('createTokenResult.error', createTokenResult.error);

						args.failed(createTokenResult.error.message);
					} else {
						const token = createTokenResult.token.id;
						// const card = createTokenResult.token.card;

						await DataService.updatePaymentMethod({
							options: {
								contactId: this.$store.getters['contact/contactId'],
								cardToken: token,
								cardHolderName: this.$store.getters['contact/contactName'],
								cardHolderEmail: this.$store.getters['contact/contactEmail'],
							},
							success: () => {
								args.success();
							},
							failed: (err) => {
								console.log('DataService.updatePaymentMethod failed()', err);
								args.failed();
							},
						});
					}
				} catch (err) {
					console.warn('cannot create card token', err);
					args.failed();
				}
			})();
		},
	},

	created() {
		this.$_apiUrl = process.env.VUE_APP_PRIMIS_API_URL;
		this.$_authToken = this.$store.getters['auth/token'];
		this.$_contactId = this.$store.getters['contact/contactId'];
	},

	mounted() {
		(async () => {
			// get stripe publishable key
			const paymentProviderConfiguration = this.$store.getters['auth/configurations'].PaymentProviderConfiguration;
			const publishableKey = atob(paymentProviderConfiguration.PublishableKey);

			// create stripe instance
			const stripe = window.Stripe(publishableKey);
			this.$_stripe = stripe;

			// create payment element
			const elements = stripe.elements();
			this.$_elements = elements;

			const paymentElement = elements.create('card', {
				hidePostalCode: true,
			});
			this.$_paymentElement = paymentElement;

			paymentElement.on('ready', () => {
				this.$nextTick(() => {
					this.$emit('initialized', { widget: this });
				});
			});

			// mount payment element
			paymentElement.mount('#payment-element');
		})();
	},
};
</script>
