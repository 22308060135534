import DataService from '@/services/DataService';

const state = () => {
	return {
		loading: false,
		contactDetails: null,
		availableSubscriptions: [],
		tickets: {
			options: {
				filter: 'current',
				sortBy: 'LotteryCloseDate',
			},
		},
	};
};

const getters = {
	loading(state) {
		return state.loading;
	},

	contactDetails(state) {
		if (!state.contactDetails) {
			return null;
		}
		return state.contactDetails;
	},

	contactId(state) {
		if (!state.contactDetails) {
			return null;
		}
		return state.contactDetails.ContactId;
	},

	contactEmail(state) {
		if (!state.contactDetails) {
			return null;
		}
		return `${state.contactDetails.EmailAddress}`;
	},

	contactName(state) {
		if (!state.contactDetails) {
			return null;
		}
		return `${state.contactDetails.FirstName} ${state.contactDetails.Surname}`.trim();
	},

	contactInitials(state) {
		if (!state.contactDetails) {
			return null;
		}
		return `${state.contactDetails.FirstName[0]}${(state.contactDetails.Surname || [''])[0]}`.trim();
	},

	purchaseHistory(state) {
		if (!state.contactDetails) {
			return [];
		}
		return state.contactDetails.PurchaseHistory;
	},

	ticketsWithAppliedOptions(state) {
		if (!state.contactDetails) {
			return [];
		}
		const items = DataService.getTicketsWithAppliedOptions(state.contactDetails.Tickets, state.tickets.options);
		return items;
	},

	currentTickets(state) {
		if (!state.contactDetails) {
			return [];
		}
		const items = DataService.getTicketsWithAppliedOptions(state.contactDetails.Tickets, {
			filter: 'current',
			sortBy: 'LotteryCloseDate',
		});
		return items;
	},

	subscriptions(state) {
		if (!state.contactDetails) {
			return [];
		}
		return state.contactDetails.Subscriptions;
	},

	paymentMethod(state) {
		if (!state.contactDetails) {
			return null;
		}
		return state.contactDetails.PaymentMethod;
	},

	tracker(state) {
		if (!state.contactDetails) {
			return null;
		}

		const ticketsPurchased = state.contactDetails.PurchaseHistory.reduce(
			(accumulator, currentValue) => accumulator + currentValue.TicketsCount,
			0
		);

		let lotteryParticipations = 0;
		const lotteries = [];
		state.contactDetails.PurchaseHistory.forEach((ph) => {
			if (ph.LotteryDrawingName && !lotteries.includes(ph.LotteryDrawingName)) {
				lotteries.push(ph.LotteryDrawingName);
				lotteryParticipations++;
			}
		});

		const totalTicketSpend = state.contactDetails.PurchaseHistory.reduce(
			(accumulator, currentValue) => accumulator + currentValue.AmountPaid,
			0
		);

		return {
			TicketsPurchased: ticketsPurchased,
			LotteryParticipations: lotteryParticipations,
			TotalTicketSpend: totalTicketSpend,
		};
	},

	availableSubscriptions(state) {
		return state.availableSubscriptions;
	},
};

const actions = {
	resetState({ commit }) {
		commit('setContactDetails', { contactDetails: null });
		commit('setAvailableSubscriptions', { subscriptions: [] });
		commit('setFilter', 'current');
		commit('setSortBy', 'LotteryCloseDate');
	},

	ensureContactDetails({ state, commit, rootState }, args) {
		if (!rootState.auth.contactId) {
			return false;
		}

		if (!state.contactDetails || args?.forceRefetch) {
			commit('setLoading', true);

			// NOTE: do several XHR here
			(async () => {
				let contactDetails = null;
				let availableSubscriptions = [];

				const tasks = [];

				tasks.push(
					DataService.getContactDetails({
						contactId: rootState.auth.contactId,
						success: (d) => {
							contactDetails = d.contactDetails;
						},
						failed: () => {},
					})
				);

				tasks.push(
					DataService.getAvailableSubscriptions({
						success: (d) => {
							availableSubscriptions = d.subscriptions;
						},
						failed: () => {},
					})
				);

				await Promise.all(tasks);

				commit('setContactDetails', { contactDetails: contactDetails });

				commit('setAvailableSubscriptions', { subscriptions: availableSubscriptions });

				commit('setLoading', false);
			})();
		}
	},
};

const mutations = {
	setLoading(state, value) {
		state.loading = value;
	},

	setFilter(state, filter) {
		state.tickets.options.filter = filter;
	},
	setSortBy(state, sortBy) {
		state.tickets.options.sortBy = sortBy;
	},

	setContactDetails(state, payload) {
		// construct fresh object
		state.contactDetails = Object.assign({}, state.contactDetails, payload.contactDetails);
	},

	setAvailableSubscriptions(state, payload) {
		const existingLotteryTypes = state.contactDetails.Subscriptions.map((_s) => _s.LotteryType);

		let availableSubscriptions = payload.subscriptions;

		availableSubscriptions = availableSubscriptions.filter((_as) => {
			return !existingLotteryTypes.includes(_as.LotteryType);
		});

		state.availableSubscriptions = availableSubscriptions;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
