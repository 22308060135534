<template>
	<div class="page">
		<div class="container">
			<div class="login-box">
				<div class="logo">
					<router-link to="/login" alt="Lucky Puppy Lottery">
						<img :src="`${publicPath}assets/lucky-puppy-lottery-logo.svg`" alt="Lucky Puppy Lottery" />
					</router-link>
				</div>
				<h1>Login to Lucky Puppy Lottery</h1>
				<div>
					<div>
						<div class="field text" :class="{ invalid: $v.d.email.$error }">
							<label for="email">Email</label>
							<input type="email" id="email" name="email" placeholder="Email" v-model="d.email" v-on:keyup.enter="handleClickEnterKey" />
							<div class="error" v-if="$v.d.email.$error">Email is required</div>
						</div>
						<div class="field text" :class="{ invalid: $v.d.password.$error }">
							<label for="password">Password</label>
							<input
								type="password"
								id="password"
								name="password"
								placeholder="Password"
								v-model="d.password"
								autocomplete="on"
								v-on:keyup.enter="handleClickEnterKey"
							/>
							<div class="error" v-if="$v.d.password.$error">Password is required</div>
						</div>
						<a href="#" class="btn" @click.prevent="handleLogin" :class="{ 'dev-disabled': requesting }">Login</a>
					</div>
				</div>
				<div class="login-footer">
					<a href="#" @click.prevent="$router.push('/forgot-password')">Forgot Password?</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DataService from '@/services/DataService';

export default {
	data() {
		return {
			publicPath: process.env.BASE_URL,
			d: {
				email: '',
				password: '',
			},
			requesting: false,
		};
	},

	validations: {
		d: {
			email: {
				required,
			},
			password: {
				required,
			},
		},
	},

	methods: {
		doLogin() {
			return new Promise((resolve, reject) => {
				this.$v.$touch();

				if (this.$v.$invalid) {
					reject();
				} else {
					(async () => {
						await DataService.login({
							email: this.d.email,
							password: this.d.password,
							success: (d) => {
								this.$store.dispatch('auth/setAuth', {
									contactId: d.contactId,
									token: d.token,
									configurations: d.configurations,
								});

								(async () => {
									await this.$store.dispatch('fetchLotteryResults');
								})();

								this.$router.push(`/dashboard`);

								resolve();
							},
							failed: (err) => {
								// extract the response
								if (err.response?.data?.errors) {
									if (err.response.data.errors.general && err.response.data.errors.general.length > 0) {
										const errorMsg = err.response.data.errors.general[0];
										// this.$toast.error(errorMsg, { timeout: false });
										this.$toast.error(errorMsg);
									}
								}
								console.warn(err);
								reject(err);
							},
						});
					})();
				}
			});
		},

		handleClickEnterKey() {
			this.handleLogin();
		},

		handleLogin() {
			if (this.requesting) {
				return false;
			}

			// (async () => {
			this.requesting = true;

			this.doLogin()
				.then(() => {
					// window.setTimeout(() => {
					// 	this.requesting = false;
					// }, 1000);
				})
				.catch(() => {
					window.setTimeout(() => {
						this.requesting = false;
					}, 1000);
				});
			// })();
		},
	},

	created() {},

	mounted() {
		document.body.id = 'login';
	},
};
</script>

<style scoped>
.dev-disabled {
	opacity: 0.5;
	cursor: none !important;
	pointer-events: none;
}
</style>
