import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import DataService from '@/services/DataService';

Vue.use(VueRouter);

let router = null;

const authGuard = function (to, from, next) {
	(async () => {
		const authorized = await DataService.authCheck();
		if (authorized) {
			next();
		} else {
			if (from.path !== '/login') {
				router.push('/login');
			}
		}
	})();
};

const routes = [
	{
		path: '/',
		beforeEnter: (to, from, next) => {
			(async () => {
				const authorized = await DataService.authCheck();
				if (authorized) {
					if (from.path !== '/dashboard') {
						router.push('/dashboard');
					}
				} else {
					if (from.path !== '/login') {
						router.push('/login');
					}
				}
			})();
		},
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
	},
	{
		path: '/forgot-password',
		name: 'forgotpassword',
		component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue'),
	},
	{
		path: '/set-password',
		name: 'setpassword',
		component: () => import(/* webpackChunkName: "about" */ '../views/SetPasswordView.vue'),
	},
	// {
	// 	path: '/change-password',
	// 	name: 'changepassword',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/ChangePasswordView.vue'),
	// },
	{
		path: '/dashboard',
		name: 'dashboard',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
	{
		path: '/my-tickets',
		name: 'mytickets',
		component: () => import(/* webpackChunkName: "about" */ '../views/MyTicketsView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
	{
		path: '/lucky-club',
		name: 'activclub',
		component: () => import(/* webpackChunkName: "about" */ '../views/ActivClubView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
	{
		path: '/results',
		name: 'results',
		component: () => import(/* webpackChunkName: "about" */ '../views/ResultsView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
	{
		path: '/purchase-history',
		name: 'purchasehistory',
		component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseHistoryView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
	{
		path: '/my-profile',
		name: 'myprofile',
		component: () => import(/* webpackChunkName: "about" */ '../views/MyProfileView.vue'),
		beforeEnter: (to, from, next) => {
			authGuard(to, from, next);
		},
	},
];

router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'exact-active',
});

export default router;
