<template>
	<div id="resume-subscription" class="modal-dialog modal-dialog-open">
		<div class="modal">
			<div class="modal-header">
				<h3>Resume Subscription</h3>
				<span class="close-modal" @click="handleClickCloseModal"></span>
			</div>
			<div class="modal-body">
				<p style="text-align: center">
					We're thrilled to have you back in the game! Simply let us know how many tickets you would like reserved. Your lottery
					subscription will be resumed immediately, and you’ll set to dive into the excitement once again.
				</p>
				<p style="text-align: center">
					<a class="btn close-modal" @click.prevent="handleClickResumeSubscription($event, subscription)"> Resume Subscription </a>
				</p>
				<p style="text-align: center">
					<a class="close-modal" @click.prevent="handleClickCloseModal"> Not Now </a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import DataService from '@/services/DataService';

export default {
	name: 'ModalResumeSubscription',
	props: {
		subscription: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {};
	},

	computed: {},

	methods: {
		handleClickCloseModal() {
			this.$emit('close');
		},

		async handleClickResumeSubscription($event, subscription) {
			const paymentMethod = this.$store.getters['contact/paymentMethod'];
			if (!paymentMethod) {
				return false;
			}

			await DataService.resumeSubscription({
				success: () => {
					this.$emit('subscription-resumed');
				},
				failed: () => {
					this.$emit('close');
				},
				options: {
					subscription: subscription,
					contactId: this.$store.getters['contact/contactId'],
					paymentMethodToken: paymentMethod.PaymentMethodToken,
				},
			});
		},
	},

	created() {},
};
</script>
