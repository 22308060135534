const state = () => {
	return {
		contactId: null,
		token: null,
		configurations: null,
	};
};

const getters = {
	contactId(state) {
		return state.contactId;
	},

	token(state) {
		return state.token;
	},

	configurations(state) {
		return state.configurations;
	},
};

const actions = {
	setAuth({ commit }, payload) {
		commit('setContactId', { contactId: payload.contactId });
		commit('setToken', { token: payload.token });
		commit('setConfigurations', { configurations: payload.configurations });
	},

	resetState({ commit }) {
		commit('setContactId', { contactId: null });
		commit('setToken', { token: null });
		commit('setConfigurations', { configurations: null });
	},
};

const mutations = {
	setContactId(state, payload) {
		state.contactId = payload.contactId;
	},

	setToken(state, payload) {
		state.token = payload.token;
	},

	setConfigurations(state, payload) {
		state.configurations = payload.configurations;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
