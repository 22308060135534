import { v4 as uuidv4 } from 'uuid';
import { getTime, isAfter } from 'date-fns';
import CommonService from '@/services/CommonService';

const modelService = {
	instantiateContactDetailsModel() {
		return {
			ContactId: 0,
			Title: '',
			FirstName: '',
			MiddleNames: null,
			Surname: '',
			AddressLine1: '',
			AddressLine2: '',
			Suburb: '',
			State: '',
			Postcode: '',
			Country: '',
			EmailAddress: '',
			PhoneMobile: '',
			PhoneHome: null,
			EmployeeId: '',
			PurchaseHistory: [],
			Tickets: [],
			Subscriptions: [],
			PaymentMethod: {
				PaymentMethodId: 0,
				PaymentMethodToken: '',
				Institution: '',
				InstrumentType: '',
				CardLast4: '',
				ExpiryDate: '',
				CardholderName: '',
			},
			ImpactStatement: '',
		};
	},

	instantiatePurchaseHistoryModel() {
		return {
			_id: uuidv4(),
			// "soldOn": "2024-03-06T13:49:16.297",
			PaymentDate: new Date('1970-01-01T00:00:00+0000'),

			// "lotteryType": "Activ Car For A Cause Lottery",
			LotteryName: '',

			// "lotteryDescription": "Car for a Cause Lottery 2023C",
			LotteryDrawingName: '',

			// "lotteryCloseDate": "2024-12-29T00:00:00",
			// "finalDrawDate": "2024-12-31T00:00:00",

			// "lotteryStatus": "Closed",

			// "ticketNumbers": "823",
			TicketNo: 0,

			// "ticketName": "Jonathon Turner",

			// "subscription": true,
			IsSubscription: false,

			// "paymentReference": "CFC23C20240306134914823",
			PaymentReference: '',

			// "lotteryLogoURL": "http://cdn.mcauto-images-production.sendgrid.net/10b9e667573baf8c/8962e092-31a6-45a4-bc75-535131c7c47a/1500x1016.png",

			// "noOfTickets": 1,
			TicketsCount: 0,

			// "totalAmountPaid": 5
			AmountPaid: 0,

			TicketPrice: 0, // CALCULATED
		};
	},

	handleContactDetailsPurchaseHistory(contactDetailsModel, raw) {
		contactDetailsModel.PurchaseHistory = [];

		const purchasedLotteryTickets = raw.purchasedLotteryTickets || [];

		const purchaseHistory = purchasedLotteryTickets.map((_t) => {
			const model = modelService.instantiatePurchaseHistoryModel();

			model.PaymentDate = new Date(_t.soldOn);
			model.LotteryName = _t.lotteryType;
			model.LotteryDrawingName = _t.lotteryDescription;
			model.TicketNo = _t.ticketNumbers;
			model.IsSubscription = _t.subscription;
			model.PaymentReference = _t.paymentReference;
			model.TicketsCount = _t.noOfTickets;
			model.AmountPaid = _t.totalAmountPaid;

			// let ticketsCount = 0;
			// const byComma = _t.ticketNumbers.split(',');
			// for (let i = 0; i < byComma.length; i++) {
			// 	const perByComma = byComma[i];
			// 	const perDash = perByComma.split('-');
			// 	if (perDash.length === 1) {
			// 		ticketsCount += 1;
			// 	}
			// 	if (perDash.length === 2) {
			// 		ticketsCount += parseInt(perDash[1], 10) - parseInt(perDash[0], 10) + 1;
			// 	}
			// }
			// model.TicketsCount = ticketsCount;

			model.TicketPrice = _t.totalAmountPaid / _t.noOfTickets;

			return model;
		});

		contactDetailsModel.PurchaseHistory = purchaseHistory;
	},

	instantiateTicketModel() {
		return {
			_id: uuidv4(),
			LotteryLogo: CommonService.emptyLotteryLogo,
			LotteryName: '',
			LotteryDrawingName: '',
			TicketNo: '',
			LotteryCloseDate: new Date('1970-01-01T00:00:00+0000'),
			LotteryDrawDate: new Date('1970-01-01T00:00:00+0000'),
			LotteryStatus: '',
		};
	},

	handleContactDetailsTickets(contactDetailsModel, raw) {
		contactDetailsModel.Tickets = [];

		const purchasedLotteryTickets = raw.purchasedLotteryTickets || [];

		const tickets = purchasedLotteryTickets.map((_t) => {
			const model = modelService.instantiateTicketModel();

			// 	// 	model.Id = raw.Id;
			model.LotteryLogo = _t.lotteryLogoURL;
			model.LotteryName = _t.lotteryType;
			model.LotteryDrawingName = _t.lotteryDescription;
			model.TicketNo = _t.ticketNumbers;
			model.LotteryCloseDate = new Date(_t.lotteryCloseDate);
			model.LotteryDrawDate = new Date(_t.finalDrawDate);
			model.LotteryStatus = _t.lotteryStatus;

			return model;
		});

		contactDetailsModel.Tickets = tickets;
	},

	instantiateSubscriptionModel() {
		return {
			_id: uuidv4(),
			LotteryLogo: CommonService.emptyLotteryLogo,
			LotteryName: '',
			LotteryType: '',
			TicketsCount: 0,
			TicketPrice: 0,
			ActiveFrom: new Date('1970-01-01T07:47:57+0000'),
			IsActive: false,
		};
	},

	handleContactDetailsSubscriptions(contactDetailsModel, raw) {
		contactDetailsModel.Subscriptions = [];

		const lotterySubscriptions = raw.lotterySubscriptions || [];

		const subscriptions = lotterySubscriptions.map((_t) => {
			const model = modelService.instantiateSubscriptionModel();

			model.LotteryLogo = _t.lotteryLogoURL;
			model.LotteryName = _t.description;
			model.LotteryType = _t.lotteryType;
			model.TicketsCount = _t.noOfTickets;
			model.TicketPrice = _t.ticketPrice;
			// model.ActiveFrom = new Date(_t.activeFrom);
			model.ActiveFrom = new Date(_t.subscriptionStartDate);
			model.IsActive = isAfter(new Date(), model.ActiveFrom);

			return model;
		});

		contactDetailsModel.Subscriptions = subscriptions;
	},

	createContactDetailsModel(raw) {
		const model = modelService.instantiateContactDetailsModel();

		model.ContactId = raw.contactId;
		model.Title = raw.title;
		model.FirstName = raw.firstName;
		model.MiddleNames = raw.middleNames;
		model.Surname = raw.surname;
		model.AddressLine1 = raw.addressLine1;
		model.AddressLine2 = raw.addressLine2;
		model.Suburb = raw.suburb;
		model.State = raw.state;
		model.Postcode = raw.postcode;
		model.Country = raw.country;
		model.EmailAddress = raw.emailAddress;
		model.PhoneMobile = raw.phoneMobile;
		model.PhoneHome = raw.phoneHome;
		model.EmployeeId = raw.employeeId;
		model.ImpactStatement = raw.impactStatement;

		modelService.handleContactDetailsPurchaseHistory(model, raw);

		modelService.handleContactDetailsTickets(model, raw);

		modelService.handleContactDetailsSubscriptions(model, raw);

		/**
		 * Payment method
		 */
		if (raw.paymentMethods && raw.paymentMethods.length > 0) {
			const paymentMethod = raw.paymentMethods[0];
			if (paymentMethod) {
				model.PaymentMethod.PaymentMethodId = paymentMethod.paymentMethodId;
				model.PaymentMethod.PaymentMethodToken = paymentMethod.paymentMethodToken;
				model.PaymentMethod.Institution = paymentMethod.institution;
				model.PaymentMethod.InstrumentType = paymentMethod.instrumentType;
				model.PaymentMethod.CardLast4 = paymentMethod.cardLast4;
				model.PaymentMethod.ExpiryDate = paymentMethod.expiryDate;
				model.PaymentMethod.CardholderName = paymentMethod.cardholderName;
			}
		}

		return model;
	},

	createAvailableSubscriptionsModel(raw) {
		const subscriptions = [];

		if (raw && raw.length > 0) {
			raw.forEach((_t) => {
				const model = modelService.instantiateSubscriptionModel();

				model.LotteryLogo = _t.logourl;
				model.LotteryName = _t.description;
				model.LotteryType = _t.lotteryType;
				model.TicketsCount = _t.timesPerYear;
				model.TicketPrice = _t.ticketPrice;
				model.ActiveFrom = null; // NOTE: to state that there is no ActiveFrom

				subscriptions.push(model);
			});
		}

		return subscriptions;
	},

	createActiveLotteriesModel(raw) {
		const lotteries = [];

		if (raw && raw.length > 0) {
			raw.forEach((_t) => {
				const model = {
					_id: uuidv4(),
					LotteryCode: _t.lotteryCode,
					Description: _t.description,
					PromoImage: _t.promoImage || CommonService.activLogo,
					LotteryLink: _t.lotteryLink,
				};

				lotteries.push(model);
			});
		}

		return lotteries;
	},

	instantiateLotteryResultModel() {
		return {
			_id: uuidv4(),
			Description: '',
			WinnerTicketNumber: 0,
			WinnerSummary: '',
		};
	},

	instantiateLotteryResultsModel() {
		return {
			_id: uuidv4(),
			LotteryCode: '',
			Description: '',
			DrawDate: new Date('1970-01-01T07:47:57+0000'),
			Results: [],
		};
	},

	createLotteryResultsModel(raw) {
		let results = [];

		if (raw && raw.length > 0) {
			raw.forEach((_t) => {
				const resultsModel = modelService.instantiateLotteryResultsModel();

				resultsModel.LotteryCode = _t.lotteryCode;
				resultsModel.Description = _t.description;
				resultsModel.DrawDate = new Date(_t.finalDrawDate);
				resultsModel.Results = [];

				if (_t.lotteryResults && _t.lotteryResults.length > 0) {
					resultsModel.Results = _t.lotteryResults.map((_r) => {
						const model = modelService.instantiateLotteryResultModel();

						model.Description = _r.prizeDescription;
						model.WinnerTicketNumber = _r.ticketNumber;
						model.WinnerSummary = _r.winnerName;

						return model;
					});
				}

				results.push(resultsModel);
			});
		}

		results = results.sort((a, b) => {
			const tsA = getTime(a.DrawDate);
			const tsB = getTime(b.DrawDate);
			return tsB - tsA;
		});

		return results;
	},
};

export default modelService;
