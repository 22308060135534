<template>
	<div>
		<router-view />

		<ModalManageSubscription
			v-if="manageSubscription.showModal"
			:subscription="manageSubscription.targetSubscription"
			@close="handleCloseManageSubscriptionModal"
			@subscription-updated="handleUpdated"
		></ModalManageSubscription>

		<ModalSubscribe
			v-if="subscribe.showModal"
			:subscription="subscribe.targetSubscription"
			@close="handleCloseSubscribeModal"
			@subscription-subscribed="handleSubscribed"
		></ModalSubscribe>

		<ModalCancelSubscription
			v-if="cancelSubscription.showModal"
			:subscription="cancelSubscription.targetSubscription"
			@close="handleCloseCancelSubscriptionModal"
			@subscription-cancelled="handleCancelled"
		></ModalCancelSubscription>

		<ModalPauseSubscription
			v-if="pauseSubscription.showModal"
			:subscription="pauseSubscription.targetSubscription"
			@close="handleClosePauseSubscriptionModal"
			@subscription-paused="handlePaused"
		></ModalPauseSubscription>

		<ModalResumeSubscription
			v-if="resumeSubscription.showModal"
			:subscription="resumeSubscription.targetSubscription"
			@close="handleCloseResumeSubscriptionModal"
			@subscription-resumed="handleResumed"
		></ModalResumeSubscription>

		<ModalChangePaymentMethod
			v-if="changePaymentMethod.showModal"
			@close="handleCloseChangePaymentMethodModal"
			@payment-method-changed="handlePaymentMethodChanged"
		></ModalChangePaymentMethod>
	</div>
</template>

<script>
import CommonService from '@/services/CommonService';
import ModalManageSubscription from '@/components/subscriptions/ModalManageSubscription.vue';
import ModalSubscribe from '@/components/subscriptions/ModalSubscribe.vue';
import ModalCancelSubscription from '@/components/subscriptions/ModalCancelSubscription.vue';
import ModalPauseSubscription from '@/components/subscriptions/ModalPauseSubscription.vue';
import ModalResumeSubscription from '@/components/subscriptions/ModalResumeSubscription.vue';
import ModalChangePaymentMethod from '@/components/subscriptions/ModalChangePaymentMethod.vue';
import { format, parseISO } from 'date-fns';

export default {
	name: 'ActivFApp',
	components: {
		ModalManageSubscription,
		ModalSubscribe,
		ModalCancelSubscription,
		ModalPauseSubscription,
		ModalResumeSubscription,
		ModalChangePaymentMethod,
	},

	data() {
		return {
			manageSubscription: {
				showModal: false,
				targetSubscription: null,
			},

			subscribe: {
				showModal: false,
				targetSubscription: null,
			},

			cancelSubscription: {
				showModal: false,
				targetSubscription: null,
			},

			pauseSubscription: {
				showModal: false,
				targetSubscription: null,
			},

			resumeSubscription: {
				showModal: false,
				targetSubscription: null,
			},

			changePaymentMethod: {
				showModal: false,
			},
		};
	},

	methods: {
		// ----- Manage Subscription -----
		handleCloseManageSubscriptionModal() {
			this.manageSubscription.showModal = false;
			this.manageSubscription.targetSubscription = null;
		},
		handleUpdated() {
			this.handleCloseManageSubscriptionModal();

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ----- Subscribe -----
		handleCloseSubscribeModal() {
			this.subscribe.showModal = false;
			this.subscribe.targetSubscription = null;
		},
		handleSubscribed(args) {
			this.$toast.success(`You are now subscribed to ${args.subscription.LotteryName}`, {
				timeout: 10000,
			});

			this.handleCloseSubscribeModal();

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ----- Cancel Subscription -----
		handleCloseCancelSubscriptionModal() {
			this.cancelSubscription.showModal = false;
			this.cancelSubscription.targetSubscription = null;
		},
		handleCancelled(args) {
			this.$toast.success(`You are no longer subscribed to ${args.subscription.LotteryName}`, {
				timeout: 10000,
			});

			this.handleCloseCancelSubscriptionModal();
			this.handleCloseManageSubscriptionModal();

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ----- Pause Subscription -----
		handleClosePauseSubscriptionModal() {
			this.pauseSubscription.showModal = false;
			this.pauseSubscription.targetSubscription = null;
		},
		handlePaused(args) {
			const pausedUntil = parseISO(args.pausedUntil);
			const formattedPausedUntil = format(pausedUntil, 'dd MMMM yyyy');
			this.$toast.success(`Your subscription to ${args.subscription.LotteryName} is on hold until ${formattedPausedUntil}`, {
				timeout: 10000,
			});

			this.handleClosePauseSubscriptionModal();
			this.handleCloseManageSubscriptionModal();

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ----- Resume Subscription -----
		handleCloseResumeSubscriptionModal() {
			this.resumeSubscription.showModal = false;
			this.resumeSubscription.targetSubscription = null;
		},
		handleResumed() {
			this.handleCloseResumeSubscriptionModal();
			this.handleCloseManageSubscriptionModal();

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ----- Change Payment Method -----
		handleCloseChangePaymentMethodModal() {
			this.changePaymentMethod.showModal = false;
		},
		handlePaymentMethodChanged() {
			this.$toast.success(`Your payment method has been updated.`, {
				timeout: 10000,
			});

			CommonService.eventBus.$emit('refresh-activ-club-page', {});
		},

		// ---- close all modal ----
		closeAllModal() {
			this.manageSubscription.showModal = false;
			this.subscribe.showModal = false;
			this.cancelSubscription.showModal = false;
			this.pauseSubscription.showModal = false;
			this.resumeSubscription.showModal = false;
			this.changePaymentMethod.showModal = false;
		},
	},

	created() {
		// ----- Manage Subscription -----
		this.$_manageSubscriptionHandler = (args) => {
			this.closeAllModal();
			this.manageSubscription.targetSubscription = args.subscription;
			this.manageSubscription.showModal = true;
		};
		CommonService.eventBus.$on('manage-subscription', this.$_manageSubscriptionHandler);

		// ----- Subscribe -----
		this.$_subscribeHandler = (args) => {
			this.closeAllModal();
			this.subscribe.targetSubscription = args.subscription;
			this.subscribe.showModal = true;
		};
		CommonService.eventBus.$on('subscribe', this.$_subscribeHandler);

		// ----- Cancel Subscription -----
		this.$_cancelSubscriptionHandler = (args) => {
			this.closeAllModal();
			this.cancelSubscription.targetSubscription = args.subscription;
			this.cancelSubscription.showModal = true;
		};
		CommonService.eventBus.$on('cancel-subscription', this.$_cancelSubscriptionHandler);

		// ----- Pause Subscription -----
		this.$_pauseSubscriptionHandler = (args) => {
			this.closeAllModal();
			this.pauseSubscription.targetSubscription = args.subscription;
			this.pauseSubscription.showModal = true;
		};
		CommonService.eventBus.$on('pause-subscription', this.$_pauseSubscriptionHandler);

		// ----- Resume Subscription -----
		this.$_resumeSubscriptionHandler = (args) => {
			this.closeAllModal();
			this.resumeSubscription.targetSubscription = args.subscription;
			this.resumeSubscription.showModal = true;
		};
		CommonService.eventBus.$on('resume-subscription', this.$_resumeSubscriptionHandler);

		// ----- Change Payment Method -----
		this.$_changePaymentMethodHandler = (args) => {
			this.closeAllModal();
			this.changePaymentMethod.showModal = true;
		};
		CommonService.eventBus.$on('change-payment-method', this.$_changePaymentMethodHandler);
	},

	beforeDestroy() {
		CommonService.eventBus.$off('manage-subscription', this.$_manageSubscriptionHandler);
		CommonService.eventBus.$off('subscribe', this.$_subscribeHandler);
		CommonService.eventBus.$off('cancel-subscription', this.$_cancelSubscriptionHandler);
		CommonService.eventBus.$off('pause-subscription', this.$_pauseSubscriptionHandler);
		CommonService.eventBus.$off('resume-subscription', this.$_resumeSubscriptionHandler);
		CommonService.eventBus.$off('change-payment-method', this.$_changePaymentMethodHandler);
	},
};
</script>
