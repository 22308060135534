<template>
	<div class="modal-dialog modal-dialog-open" v-if="subscription">
		<div class="modal">
			<div class="modal-header">
				<h3>Manage Your Subscription</h3>
				<span class="close-modal" @click="handleClickCloseModal"></span>
			</div>
			<div class="modal-body">
				<p>
					Simply let us know how many tickets you would like reserved, and we’ll process an automatic payment each month and automatically
					send you your tickets.
				</p>
				<div class="manage-subscripton">
					<div class="subscriptionCard">
						<div class="image">
							<img :src="subscription.LotteryLogo" :alt="subscription.LotteryName" />
						</div>
						<div class="details">
							<div class="info">
								<h3>{{ subscription.LotteryName }}</h3>
								<div class="price">{{ subscription.TicketsCount }} Tickets for {{ formatPrice(subscription.TicketPrice) }}</div>
							</div>
							<div class="ticket-toggler">
								<div class="ticket-qty">
									<button type="button" class="btn-minus" @click.prevent="handleClickDecreaseTicketToBuy"></button>
									<input type="text" readonly="" class="ticket-count" :value="ticketing.amountToBuy" />
									<button type="button" class="btn-plus" @click.prevent="handleClickIncreaseTicketToBuy"></button>
								</div>
								<div class="ticket-total">{{ formatPrice(ticketingTotalPrice) }}</div>
							</div>
						</div>
					</div>
					<div class="subscriptionActions">
						<div class="left">
							<a class="pause-subscription-open link-muted" @click.prevent="handleClickPauseSubscription($event, subscription)">
								Pause Subscription
							</a>
							<a class="cancel-subscription-open link-muted" @click.prevent="handleClickCancelSubscription($event, subscription)">
								Cancel Subscription
							</a>
						</div>
						<div class="right">
							<a class="btn close-modal" @click.prevent="handleClickUpdateSubscription($event, subscription)"> Update Subscription </a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommonService from '@/services/CommonService';
import DataService from '@/services/DataService';

export default {
	name: 'ModalManageSubscription',
	props: {
		subscription: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			ticketing: {
				amountToBuy: 1,
			},
		};
	},

	computed: {
		ticketingTotalPrice() {
			return this.ticketing.amountToBuy * this.subscription.TicketPrice;
		},
	},

	methods: {
		formatPrice(ticketPrice) {
			return CommonService.formatCurrency(ticketPrice);
		},

		handleClickDecreaseTicketToBuy() {
			if (this.ticketing.amountToBuy === 0) {
				return false;
			}
			this.ticketing.amountToBuy--;
		},
		handleClickIncreaseTicketToBuy() {
			this.ticketing.amountToBuy++;
		},

		handleClickPauseSubscription($event, subscription) {
			CommonService.eventBus.$emit('pause-subscription', {
				subscription,
				source: this,
			});
		},

		handleClickCancelSubscription($event, subscription) {
			CommonService.eventBus.$emit('cancel-subscription', {
				subscription,
				source: this,
			});
		},

		handleClickCloseModal() {
			this.$emit('close');
		},

		async handleClickUpdateSubscription($event, subscription) {
			// validate
			const paymentMethod = this.$store.getters['contact/paymentMethod'];
			if (!paymentMethod) {
				return false;
			}

			await DataService.updateSubscription({
				success: () => {
					this.$emit('subscription-updated');
				},
				failed: () => {
					this.$emit('close');
				},
				options: {
					contactId: this.$store.getters['contact/contactId'],
					subscription: subscription,
					noOfTickets: this.ticketing.amountToBuy,
					paymentMethodToken: paymentMethod.PaymentMethodToken,
				},
			});
		},
	},

	created() {
		if (this.subscription) {
			this.ticketing.amountToBuy = this.subscription.TicketsCount;
		}
	},
};
</script>
