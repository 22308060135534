import axios from 'axios';

const httpClient = {
	addRequestInterceptors(instance) {
		instance.interceptors.request.use(
			(config) => {
				return Promise.resolve(config);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	},

	get(url, config) {
		const instance = axios.create();
		httpClient.addRequestInterceptors(instance);
		return instance.get(url, config);
	},

	post(url, data, config) {
		const instance = axios.create();
		httpClient.addRequestInterceptors(instance);
		return instance.post(url, data, config);
	},

	put(url, data, config) {
		const instance = axios.create();
		httpClient.addRequestInterceptors(instance);
		return instance.put(url, data, config);
	},
};

export default {
	get: httpClient.get,
	post: httpClient.post,
	put: httpClient.put,
};
